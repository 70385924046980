import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useSalesStore = defineStore ({
  id:'sales', 
  state: () => ({
    companyId: '',
    cdpProfileId: '',
    orderId: '',
    orders: [],
    ordersCount: '',
    cxdpId: '',
    sourceId: '',
    orderName: '',
    state: '',
    product: '',
    price: '',
    createdAt: '',
    updatedAt: '',
    ordersProfile: [],
    phone: '',
    email: '',
    userSourceId: '',
    userCxdpId: '',
    source: [],
    sourceName: '',
    sourceCount: '',
    productId: '',
    products: [],
    externalId: '',
    url:'',
    productName:'',
    images: [],
    productsCount: '',
    currentPage: 1,
    perPage: 20,
    lastPage: 1,
    isLoading: false
  }),
  getters: {
    sourceData: (state) => {
      let sourceData = {}
      if (state.sourceName) {
        sourceData['name'] = state.sourceName
      }

      if (state.companyId) {
        sourceData['company_id'] = state.companyId
      }

      return sourceData
    },
    productData: (state) => {
      let productData = {}
      if (state.productName) {
        productData['product_name'] = state.productName
      }

      if (state.price) {
        productData['price_cents'] = state.price
      }

      if (state.url) {
        productData['url'] = state.url
      }
      
      if (state.images) {
        productData['picture_urls'] = state.images
      }

      return productData
    },
    preparedOrders: (state) => state.orders.map(order => {
      return {
        id: order.id.toString(),
        sourceName: order?.source.name || '-' ,
        createdAt: order.created_at !== null ? order.created_at: '',
        products: order?.line_items_sum_quantity || order?.line_items_sum_quantity || '-',
        sum: order?.total_price_cents || order?.total_price_cents || '-',
        state: order.operation_status,
        externalSourceName: order?.external_order_id || order?.external_order_id || '-',
        ownerName: order?.cdp_profile_id|| '',
      }
    }),
    preparedOrdersProfile: (state) => state.ordersProfile.map(order => {
      return {
        id: order.id.toString(),
        name: order?.id || '-' ,
        sourceName: order?.source.name || '-' ,
        createdAt: order.created_at !== null ? order.created_at: '',
        products: order?.line_items_sum_quantity || order?.line_items_sum_quantity || '-',
        sum: order?.total_price_cents || order?.total_price_cents || '-',
        state: order.operation_status,
        externalSourceName: order?.external_order_id || order?.external_order_id || '-',
        ownerName: order?.cdp_profile_id || '',
      }
    }),
    preparedSource: (state) => state.source.map(source => {
      return {
        id: source.id.toString(),
        name: source.name,
        companyId: source.company_id
      }
    }),
    preparedProducts: (state) => state.products.map(product => {
      return {
        id: product.id.toString(),
        name: product?.product_name || '-',
        cxdpId: product?.description || '-',
        sourceId: product?.source_id || '-',
        url: product.url,
        img: product.picture_urls != null && product.picture_urls.length > 0 ? product.picture_urls[0] : null,
        price: product?.price_cents || '-',
        quantity: product?.quantity || '-'
      }
    }),
    preparedOrderProducts: (state) => state.products.map(product => {
      return {
        id: product.id.toString(),
        name: product.product?.product_name || '-',
        cxdpId: product.product?.description || '-',
        sourceId: product.product?.source_id || '-',
        url: product.product.url,
        img: product.product.picture_urls != null && product.product.picture_urls.length > 0 ? product.product.picture_urls[0] : null,
        price: product.product?.price_cents || '-',
        quantity: product?.quantity || '-'
      }
    }),
    orderSourcesForSelect: (state) => state.source.map(source => {
      return {
        value: source.id.toString(),
        text: source.name,
        companyId: source.company_id
      }
    }),
  },
  actions: {
    setOrderDefault() {
      this.cxdpId = ''          
      this.sourceId = ''
      this.orderName = ''
      this.state = ''
      this.product = ''
      this.price = ''
      this.createdAt = ''
      this.updatedAt = ''
      this.phone = ''
      this.email = ''
      this.userSourceId = ''
      this.userCxdpId = ''
      this.products = []
    },
    setSalesPaginationDefault() {
      this.currentPage = 1
      this.perPage = 20
      this.lastPage = 1
    },
    async getOrders(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get('api/v1/orders', {page: this.campaignCurrentPage, per: perPage})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.orders = response.data.data
            this.ordersCount = response.data.total
            this.currentPage = response.data.current_page
            this.lastPage = response.data.current_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getOrder() {
      this.isLoading = true
      axios
        .get(`api/v1/orders/${this.orderId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            if(response.data.customer.length > 0) {
              this.cdpProfileId = response.data.customer[0].id
              this.phone = response.data.customer[0]?.personal_phone || '-'
              this.email = response.data.customer[0]?.personal_email || '-'
              this.userSourceId = response.data.customer[0]?.external_system_id || '-'
            }        
            this.cxdpId = response.data.order.id            
            this.sourceName = response.data.order.source.name
            this.sourceId = response.data.order.source.id
            this.externalId = response.data.order?.external_order_id || '-'
            this.orderName = response.data.order.source_id
            this.state = response.data.order.operation_status
            this.product = response.data.order_line_items.length
            this.price = response.data.order.total_price_cents
            this.createdAt = response.data.order.created_at
            this.updatedAt = response.data.order.updated_at
            this.products = response.data?.order_line_items || []
            if (this.products) this.products = this.products.filter(item => item.product != null)
            this.productsCount  = response.data?.order_line_items.length || null                
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProfileOrders(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get(`api/v1/cdp_profiles/${this.cdpProfileId}/orders`, {page: this.campaignCurrentPage, per: perPage})
        .then((response) => {
          if (response.status === 200) {
            this.ordersProfile = response.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.current_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProducts(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get('api/v1/products', {page: this.campaignCurrentPage, per: perPage})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.products = response.data.data
            this.productsCount = response.data.total
            this.currentPage = response.data.current_page
            this.lastPage = response.data.current_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProductsWithCompany(companyId, callback = {}) {
      let perPage = 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }
      this.isLoading = true
      axios
        .get('api/v1/products', {page: this.campaignCurrentPage, per: perPage})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.products = response.data.data
            this.productsCount = response.data.total
            this.currentPage = response.data.current_page
            this.lastPage = response.data.current_page

            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getProduct() {
      this.isLoading = true
      axios
        .get(`api/v1/products/${this.productId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.cxdpId = response.data?.id || '-'
            this.productName = response.data?.product_name || '-'
            this.sourceId = response.data?.source_id || '-'
            this.externalId = response.data?.external_product_id || '-'
            this.price = response.data?.price_cents || '-'            
            this.images = response.data?.picture_urls || []
            this.url = response.data.url
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateProduct() {
      this.isLoading = true
      axios
        .post(`api/v1/products/${this.productId}`, this.productData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('Товар успешно обновлен')
            router.push({name: 'sales_products_show'})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSources(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get('api/v1/sources', {page: this.campaignCurrentPage, per: perPage})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.source = response.data.data
            this.sourceCount = response.data.total
            this.currentPage = response.data.current_page
            this.lastPage = response.data.current_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getSource(callback={}) {
      this.isLoading = true
      axios
        .get(`api/v1/sources/${this.sourceId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.sourceName = response.data.name
            this.companyId = response.data.company_id
            this.sourceId = response.data.id 
            if (typeof callback == 'function') {
              callback('success', response.data)
            }        
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createSource() {
      this.isLoading = true
      axios
        .post('api/v1/sources', this.sourceData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            this.getSource()
            toast.success('Источник успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateSource() {
      this.isLoading = true
      axios
        .post(`api/v1/sources/${this.sourceId}`, this.sourceData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            this.getSource()
            toast.success('Источник успешно обновлен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteSource(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let sourceId = ''
        if (typeof payload == 'object' || payload === undefined) {
          sourceId = parseInt(this.sourceId)
        } else {
          sourceId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/sources/${sourceId}`)
          .then((response) => {
            if ((response.status === 200 || response.status === 201)) {
              this.getSource()
              toast.success('Источник успешно удалёно')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
  }
})