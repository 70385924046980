import moment from 'moment-timezone'

function declOfNum(n, texts) {
  n = Math.abs(n) % 100
  let tempN = n % 10

  if (n > 10 && n < 20) {
    return texts[2]
  } else if (tempN > 1 && tempN < 5) {
    return texts[1]
  } else if (tempN == 1) {
    return texts[0]
  }

  return texts[2]
}

function getFilterText(filterRules) {
  let conditions = {
    and: 'И',
    or: 'ИЛИ'
  }
  let operators = {
    equal: "равно",
    not_equal: "не равно",
    in: "из указанных",
    not_in: "не из указанных",
    less: "меньше",
    less_or_equal: "меньше или равно",
    greater: "больше",
    greater_or_equal: "больше или равно",
    between: "между",
    not_between: "не между",
    begins_with: "начинается с",
    not_begins_with: "не начинается с",
    contains: "содержит",
    not_contains: "не содержит",
    ends_with: "оканчивается на",
    not_ends_with: "не оканчивается на",
    is_empty: "пустая строка",
    is_not_empty: "не пустая строка",
    is_null: "пусто",
    is_not_null: "не пусто",
    json_contains: "входит"
  }
  let names = {
    pixel_id: 'Источник',
    personal_name: 'Имя',
    personal_surname: 'Фамилия',
    bio_gender: 'Пол',
    personal_email: 'Email',
    personal_phone: 'Телефон',
    bio_birth: 'Дата рождения',
    age: 'Возраст',
    personal_city: 'Город',
    personal_country: 'Страна',
    interests: 'Интерес',
    current_time_utc: 'Часовой пояс',
    personal_data_legals: 'Согласие на обработку перс. данных',
    ids_socials_vk: 'Список ID ВКонтакте',
    ids_socials_odn: 'Список ID Одноклассники',
    ids_pixels_yandex: 'Список ID Яндекс',
    ids_pixels_mailru: 'Список ID Mail.ru',
    ids_pixels_google: 'Список ID Google',
    visits_count: 'Количество визитов',
    visits_urls: 'Посещенные страницы',
    visits_avg_surfing_times_of_day: 'Среднее время интернет-серфинга в день',
    visits_avg_duration_sec: 'Общее количество секунд на сайтах',
    created_at: 'Дата создания',
    updated_at: 'Дата обновления',
    visits_ips: 'IP-адреса',
    mac_address: 'MAC-адрес',
    visits_fingerprint_ids: 'Fingerprint IDs',
    mailing_consent: 'Согласие на рассылку',
    visits_session_ids: 'visits_session_ids',
    visits_url_keywords: 'Параметры из URL',
    visits_utm_keywords: 'UTM Метки',
    visits_device_types: 'Типы устройств',
    visits_useragents: 'Useragents',
    visits_devices: 'Устройства',
    visits_display_resolutions: 'Разрешение экранов',
    pixel_event_id: 'Событие',
    url: 'URL',
    product_id: 'ID продукта',
    segment_id: 'ID сегмента',
    old_state: 'Старый статус',
    new_state: 'Новый статус',
    email_campaign_id: "ID email-кампании"
  }
  let emailCampaignStatus = {
    'Delivered': 'Доставлено',
    'Sent': 'Отправлено',
    'Unsubscribed': 'Отписался',
    'Opened': 'Открыто',
    'Clicked': 'Переход по ссылкам',
    'Pending': 'Ожидает',
    'Sending': 'Отправляется',
    'Error': 'Ошибка доставки',
    'Spam': 'Спам',
    'SoftBounced': 'SoftBounced',
    'HardBounced': 'HardBounced' 
  }

  function handlerGroupRules(groupRules) {
    let textArray = []
  
    groupRules.rules.forEach(rule => {
      if (rule.condition !== undefined) {
        textArray.push(handlerGroupRules(rule))
      } else {
        textArray.push(handlerBaseRule(rule))
      }
    })
  
    return textArray.length > 1 ? `(${textArray.filter(element => element !== '').join(` ${conditions[groupRules.condition.toLowerCase()]} `)})` : textArray.filter(element => element !== '').join(` ${conditions[groupRules.condition.toLowerCase()]} `)
  }
  
  function handlerBaseRule(rule) {
    let fieldName = names[rule.id.includes('.') ? rule.id.split('.')[1] : rule.id] || rule?.label_name || rule.id

    if (rule.operator === 'not_equal' && (rule.value === '' || rule.value === null)) {
      return `${fieldName} не пусто`
    }

    if (rule.operator === 'equal' && rule.value === null) {
      return `${fieldName} пусто`
    }

    if (rule.operator === 'json_contains') {
      return `изменилось поле ${fieldName}`
    }

    if (rule.operator == 'is_null' || rule.operator == 'is_not_null' || rule.operator == 'is_empty' || rule.operator == 'is_not_empty') {
      return `${fieldName} равно ${operators[rule.operator]}`
    }

    if ((rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'old_state' || (rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'new_state') {
      return `${fieldName} ${operators[rule.operator]} ${emailCampaignStatus[rule.value]}`
    }

    if (fieldName == 'Событие') {
      return `${fieldName} ${operators[rule.operator]} ${rule?.event_name ? rule.event_name : rule.value}`
    }

    if (rule.value == 'App\\Models\\EmailCampaign' || rule.value == 'App\\Models\\ScenarioStepRun') {
      return ''
    }
  
    return `${fieldName} ${operators[rule.operator]} ${rule?.value_name ? rule.value_name : rule.value}`
  }

  if (filterRules === '') {
    return ''
  }

  let textArray = []
  let validFilterRules = typeof(filterRules) !== 'object' ? JSON.parse(filterRules) : filterRules

  if (validFilterRules.rules === undefined) {
    return ''
  }

  validFilterRules.rules.forEach(rule => {
    if (rule.condition !== undefined) {
      textArray.push(handlerGroupRules(rule))
    } else {
      textArray.push(handlerBaseRule(rule))
    }
  });

  return textArray.join(` ${conditions[validFilterRules.condition.toLowerCase()]} `)
}

function getFilterFirstLevelText(filterRules) {
  let conditions = {
    and: 'И',
    or: 'ИЛИ'
  }
  let operators = {
    equal: "равно",
    not_equal: "не равно",
    in: "из указанных",
    not_in: "не из указанных",
    less: "меньше",
    less_or_equal: "меньше или равно",
    greater: "больше",
    greater_or_equal: "больше или равно",
    between: "между",
    not_between: "не между",
    begins_with: "начинается с",
    not_begins_with: "не начинается с",
    contains: "содержит",
    not_contains: "не содержит",
    ends_with: "оканчивается на",
    not_ends_with: "не оканчивается на",
    is_empty: "пустая строка",
    is_not_empty: "не пустая строка",
    is_null: "пусто",
    is_not_null: "не пусто",
    json_contains: "входит"
  }
  let names = {
    pixel_id: 'Источник',
    personal_name: 'Имя',
    personal_surname: 'Фамилия',
    bio_gender: 'Пол',
    personal_email: 'Email',
    personal_phone: 'Телефон',
    bio_birth: 'Дата рождения',
    age: 'Возраст',
    personal_city: 'Город',
    personal_country: 'Страна',
    interests: 'Интерес',
    current_time_utc: 'Часовой пояс',
    personal_data_legals: 'Согласие на обработку перс. данных',
    ids_socials_vk: 'Список ID ВКонтакте',
    ids_socials_odn: 'Список ID Одноклассники',
    ids_pixels_yandex: 'Список ID Яндекс',
    ids_pixels_mailru: 'Список ID Mail.ru',
    ids_pixels_google: 'Список ID Google',
    visits_count: 'Количество визитов',
    visits_urls: 'Посещенные страницы',
    visits_avg_surfing_times_of_day: 'Среднее время интернет-серфинга в день',
    visits_avg_duration_sec: 'Общее количество секунд на сайтах',
    created_at: 'Дата создания',
    updated_at: 'Дата обновления',
    visits_ips: 'IP-адреса',
    mac_address: 'MAC-адрес',
    visits_fingerprint_ids: 'Fingerprint IDs',
    mailing_consent: 'Согласие на рассылку',
    visits_session_ids: 'visits_session_ids',
    visits_url_keywords: 'Параметры из URL',
    visits_utm_keywords: 'UTM Метки',
    visits_device_types: 'Типы устройств',
    visits_useragents: 'Useragents',
    visits_devices: 'Устройства',
    visits_display_resolutions: 'Разрешение экранов',
    pixel_event_id: 'ID события',
    url: 'URL',
    product_id: 'ID продукта',
    segment_id: 'ID сегмента',
    old_state: 'Старый статус',
    new_state: 'Новый статус',
    email_campaign_id: "ID email-кампании",
    step_email_campaign_id: "Шаг email-кампании"
  }
  let emailCampaignStatus = {
    'Delivered': 'Доставлено',
    'Sent': 'Отправлено',
    'Unsubscribed': 'Отписался',
    'Opened': 'Открыто',
    'Clicked': 'Переход по ссылкам',
    'Pending': 'Ожидает',
    'Sending': 'Отправляется',
    'Error': 'Ошибка доставки',
    'Spam': 'Спам',
    'SoftBounced': 'SoftBounced',
    'HardBounced': 'HardBounced' 
  }

  function handlerGroupRules(groupRules, condition) {
    let childs = []
    let hasGroups = false
    if (groupRules.rules.length == 2 && !groupRules.rules[0].hasOwnProperty('condition') && groupRules.rules[0].id == 'cdp_events.pixel_event_id') {
      childs.push(handlerEventRule(groupRules.rules, condition))
    } else if (groupRules.rules.length == 3 && !groupRules.rules[0].hasOwnProperty('condition') && groupRules.rules[0].id == 'cdp_events.pixel_event_id') {
      childs.push(handlerAccompanimentEventRule(groupRules.rules, condition))
    } else {
      groupRules.rules.forEach(rule => {
        if (rule.condition !== undefined) {
          hasGroups = true
        } else {
          childs.push(handlerBaseRule(rule, condition))
        }
      })
    }

    if (hasGroups) {
      childs.push({condition: conditions[condition], type: 'group'})
    }
  
    return childs
  }
  
  function handlerBaseRule(rule, condition) {
    let fieldName = names[rule.id.includes('.') ? rule.id.split('.')[1] : rule.id] || rule?.label_name || rule.id
    let currentRule = {condition: conditions[condition], type: 'rule', name: fieldName, operator: '', value: ''}

    if (rule.operator === 'not_equal' && (rule.value === '' || rule.value === null)) {
      currentRule.operator = 'не пусто'

      return currentRule
    }

    if (rule.operator === 'equal' && rule.value === null) {
      currentRule.operator = 'пусто'

      return currentRule
    }

    if (rule.operator === 'json_contains') {
      currentRule.operator = 'изменилось поле'

      return currentRule
    }

    if (rule.operator == 'is_null' || rule.operator == 'is_not_null' || rule.operator == 'is_empty' || rule.operator == 'is_not_empty') {
      currentRule.operator = `равно ${operators[rule.operator]}`

      return currentRule
    }

    currentRule.operator = operators[rule.operator]

    if ((rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'old_state' || (rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'new_state') {
      currentRule.value = emailCampaignStatus[rule.value]
    } else {
      currentRule.value = rule?.value_name ? rule.value_name : rule.value
    }

    return currentRule
  }

  function handlerEventRule(rules, condition) {
    let eventRule = rules[0]
    let rule = rules[1]
    let eventName = names[eventRule.id.includes('.') ? eventRule.id.split('.')[1] : eventRule.id] || eventRule.id
    let fieldName = names[rule.id.includes('.') ? rule.id.split('.')[1] : rule.id] || rule?.label_name || rule.id
    let currentRule = {
      condition: conditions[condition], 
      type: 'rule', 
      name: `${eventName} равно ${eventRule?.event_name ? eventRule.event_name : eventRule.value }`, operator: '', 
      value: ''
    }


    if (rule.operator === 'not_equal' && (rule.value === '' || rule.value === null)) {
      currentRule.operator = `${fieldName} не пусто`
      
      return currentRule
    }

    if (rule.operator === 'equal' && rule.value === null) {
      currentRule.operator = `${fieldName} пусто`

      return currentRule
    }

    if (rule.operator === 'json_contains') {
      currentRule.operator = `изменилось поле ${fieldName}`

      return currentRule
    }

    if (rule.operator == 'is_null' || rule.operator == 'is_not_null' || rule.operator == 'is_empty' || rule.operator == 'is_not_empty') {
      currentRule.operator = `${fieldName} равно ${operators[rule.operator]}`

      return currentRule
    }

    currentRule.operator = `${fieldName} ${operators[rule.operator]}`

    if ((rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'old_state' || (rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'new_state') {
      currentRule.value = emailCampaignStatus[rule.value]
    } else {
      currentRule.value = rule?.value_name ? rule.value_name : rule.value
    }

    return currentRule
  }

  function handlerAccompanimentEventRule(rules, condition) {
    let eventRule = rules[0]
    let rule = rules[2]
    let eventName = names[eventRule.id.includes('.') ? eventRule.id.split('.')[1] : eventRule.id] || eventRule.id
    let fieldName = names[rule.id.includes('.') ? rule.id.split('.')[1] : rule.id] || rule?.label_name || rule.id
    let currentRule = {
      condition: conditions[condition], 
      type: 'rule', 
      name: `${eventName} равно ${eventRule?.event_name ? eventRule.event_name : eventRule.value }`, operator: '', 
      value: ''
    }


    if (rule.operator === 'not_equal' && (rule.value === '' || rule.value === null)) {
      currentRule.operator = `${fieldName} не пусто`
      
      return currentRule
    }

    if (rule.operator === 'equal' && rule.value === null) {
      currentRule.operator = `${fieldName} пусто`

      return currentRule
    }

    if (rule.operator === 'json_contains') {
      currentRule.operator = `изменилось поле ${fieldName}`

      return currentRule
    }

    if (rule.operator == 'is_null' || rule.operator == 'is_not_null' || rule.operator == 'is_empty' || rule.operator == 'is_not_empty') {
      currentRule.operator = `${fieldName} равно ${operators[rule.operator]}`

      return currentRule
    }

    currentRule.operator = `${fieldName} ${operators[rule.operator]}`

    if ((rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'old_state' || (rule.id.includes('.') ? rule.id.split('.')[1] : rule.id) == 'new_state') {
      currentRule.value = emailCampaignStatus[rule.value]
    } else {
      currentRule.value = rule?.value_name ? rule.value_name : rule.value
    }

    return currentRule
  }

  if (filterRules === '' || filterRules === null) {
    return []
  }

  let firstLevelRules = []
  let validFilterRules = typeof(filterRules) !== 'object' ? JSON.parse(filterRules) : filterRules

  if (validFilterRules.rules === undefined) {
    return []
  }

  validFilterRules.rules.forEach(rule => {
    if (rule.condition !== undefined) {
      firstLevelRules.push({type: 'group', childs: handlerGroupRules(rule, rule.condition.toLowerCase())})
    } else {
      firstLevelRules.push(handlerBaseRule(rule, validFilterRules.condition.toLowerCase()))
    }
  });

  return firstLevelRules
}

function getQueryBuilderWord(category, word) {
  let words = {
    conditions: {
      and: 'И',
      or: 'ИЛИ'
    },
    operators: {
      equal: "равно",
      not_equal: "не равно",
      in: "из указанных",
      not_in: "не из указанных",
      less: "меньше",
      less_or_equal: "меньше или равно",
      greater: "больше",
      greater_or_equal: "больше или равно",
      between: "между",
      not_between: "не между",
      begins_with: "начинается с",
      not_begins_with: "не начинается с",
      contains: "содержит",
      not_contains: "не содержит",
      ends_with: "оканчивается на",
      not_ends_with: "не оканчивается на",
      is_empty: "пустая строка",
      is_not_empty: "не пустая строка",
      is_null: "пусто",
      is_not_null: "не пусто",
      json_contains: "входит"
    }
  }

  return (words[category][word] === null ? '' : words[category][word])
}

function getTranslateSystemFields(fieldName) {
  let fieldsName = {
    personal_name: 'Имя',
    personal_surname: 'Фамилия',
    personal_phone: 'Телефон',
    personal_email: 'Email',
    ids_socials_vk: 'Список ID ВКонтакте',
    ids_socials_odn: 'Список ID Одноклассники',
    ids_pixels_yandex: 'Список ID Яндекс',
    ids_pixels_mailru: 'Список ID Mail.ru',
    ids_pixels_google: 'Список ID Google',
    personal_country: 'Страна',
    personal_city: 'Город',
    current_time_utc: 'Часовой пояс',
    bio_gender: 'Пол',
    bio_birth: 'Дата рождения',
    interests: 'Интересы',
    created_at: 'Дата создания',
    pixel_id: 'Источник',
    created_at: 'Дата создания',
    updated_at: 'Дата обновления',
    visits_ips: 'IP-адреса',
    visits_fingerprint_ids: 'Fingerprint IDs',
    visits_display_resolutions: 'Разрешение экранов',
    visits_useragents: 'Useragents',
    visits_urls: 'Посещенные страницы',
    visits_session_ids: 'Список ID сессий',
    visits_utm_keywords: 'UTM Метки',
    visits_device_types: 'Типы устройств',
    visits_devices: 'Устройства',
    visits_url_keywords: 'Параметры из URL',
    visits_avg_surfing_times_of_day: 'Среднее время интернет-серфинга в день',
    visits_count: 'Количество визитов',
    personal_data_legals: 'Согласие на обработку персональных данных',
    visits_avg_surfing_times_of_day: 'Общее количество секунд на сайтах'
  }
  return fieldsName[fieldName];
}

function getTranslateStatus(state) {
  let states = {
    0: 'Ожидает',
    1: 'Отправляется',
    2: 'Ошибка',
    3: 'Отправлено',
    4: 'Доставлено',
    5: 'Открыто',
    6: 'Кликнуто',
    7: 'Отписался',
    8: 'Мягкий отказ',
    9: 'Жесткий отказ',
    10: 'Спам'
  }

  let beatyState = {
    'Delivered': 'Доставлено',
    'Sent': 'Отправлено',
    'Unsubscribed': 'Отписался',
    'Opened': 'Открыто',
    'Clicked': 'Переход по ссылкам',
    'Pending': 'Ожидает',
    'Sending': 'Отправляется',
    'Error': 'Ошибка доставки',
    'Spam': 'Спам',
    'SoftBounced': 'SoftBounced',
    'HardBounced': 'HardBounced'
  }

  return (states[state] === undefined ? beatyState[state] : states[state])
}

function getOnlyLatinSymbols(body) {
  let alphabet = {
		'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
		'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
		'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
		'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
		'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
		'ш': 'sh','щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
		'э': 'e', 'ю': 'yu', 'я': 'ya',
		'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
		'Е': 'E', 'Ё': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
		'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
		'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
		'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch',
		'Ш': 'Sh', 'Щ': 'Sch', 'Ь': '', 'Ы': 'Y', 'Ъ': '',
		'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
	}
  let transliterationText = ''
  
  for (var i = 0; i < body.length; i++){
    transliterationText += alphabet[body[i]] || body[i]
  }

  return transliterationText
}

function prettyDatetime(dateTime) {
  return moment(dateTime).format('DD.MM.YYYY H:mm')
}

function prettyDate(date) {
  return moment(date).format('DD.MM.YYYY')
}

export {
  declOfNum,
  getFilterText,
  getQueryBuilderWord,
  getTranslateSystemFields,
  getFilterFirstLevelText,
  getOnlyLatinSymbols,
  getTranslateStatus,
  prettyDatetime,
  prettyDate
}