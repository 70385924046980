function formPrettySyles(designFormFields) {
  return `<style>
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
  .external-subscribe-form-container {
    padding: 32px;
    background-color: ${designFormFields.window_bg_color};
    border-radius: 20px;
    max-width: 500px;
  }
  .external-subscribe-form-container .external-title {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 1.1;
    font-weight: bold;
    color: ${designFormFields.window_title_color};
    text-align: center;
    margin-bottom: 10px;
  }
  .external-subscribe-form-container .external-description {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 1.1;
    font-weight: normal;
    color: ${designFormFields.window_description_color};
    text-align: center;
    margin-bottom: 20px;
  }
  .external-subscribe-form-container form {
    font-family: "Inter", sans-serif;
  }
  .external-subscribe-form-container form .form-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
  }
  .external-subscribe-form-container form .form-group label {
    font-size: 14px;
    line-height: 1.1;
    font-weight: bold;
    color: ${designFormFields.fields_title_color};
    margin-bottom: 4px;
  }
  .external-subscribe-form-container strong {
    color: red;
  }
  .external-subscribe-form-container form .form-group input {
    padding: 8px 16px;
    border: 1px solid #48496433;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.1;
    color: ${designFormFields.fields_description_color};
    background-color: #FAFAFA;
    width: 100%;
    outline: none;
  }
  .external-subscribe-form-container form .form-group input::placeholder {
    color: ${designFormFields.fields_description_color};
  }
  .external-subscribe-form-container form .form-group input.error {
    border-color: red;
  }
  .external-subscribe-form-container form button {
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: bold;
    color: ${designFormFields.button_text_color};
    background-color: ${designFormFields.button_bg_color};
    width: 100%;
    margin-bottom: 20px;
  }
  .external-subscribe-form-container form button:disabled {
    opacity: 0.7;
  }
  .external-subscribe-form-container form .external-policity {
    font-size: 12px;
    line-height: 1.3;
    color: ${designFormFields.window_description_color};
    text-align: center;
  }
  .external-subscribe-form-container form .external-policity a {
    color: ${designFormFields.window_description_color};
    text-decoration: underline;
  }
</style>`
}

function formPrettyHtml(settingFormFields, fieldsFormConfig) {
  let firstPart = `
<div class="external-subscribe-form-container">
  <h2 class="external-title">${settingFormFields.content_title}</h2>
  <p class="external-description">${settingFormFields.content_description}</p>
  <form>`
  let secondPart = `
    <button type="submit">${settingFormFields.content_button_text}</button>
    <p class="external-policity">Нажимая на кнопку, вы даете согласие на обработку своих персональных данных. <a href="${settingFormFields.politics_url}" target="_blank">Подробнее</a></p>
  </form>
</div>`
  let formPart = ''

  Object.keys(fieldsFormConfig).forEach(fieldName => {
    if ([true, 'true'].includes(fieldsFormConfig[fieldName].enabled)) {
      formPart += `
    <div class="form-group">
      <label for="${fieldsFormConfig[fieldName].param}">Email<strong>*</strong></label>
      <input type="text" class="${[true, 'true'].includes(fieldsFormConfig[fieldName].isRequired) ? 'required' : ''} ${fieldsFormConfig[fieldName].validations.join(' ')}" name="${fieldsFormConfig[fieldName].param}" placeholder="${fieldsFormConfig[fieldName].placeholder}"/>
    </div>`
    }
  })

  return firstPart + formPart + secondPart
}

function formPrettyJs(settingFormFields) {
  return `
<script>
  const setStateDisabledButton = (state, formIndex) => {
    document.querySelectorAll(".external-subscribe-form-container")[formIndex].querySelector('button').disabled = state;
  };
  const validateForm = (formIndex) => {
    let hasErrors = false document.querySelectorAll(".external-subscribe-form-container")[formIndex].querySelectorAll('input').forEach(element => {
      if (!validateInput(element, formIndex, true)) hasErrors = true
    });
    setStateDisabledButton(hasErrors, formIndex);

    return !hasErrors
  };
  const validateInput = (element, formIndex, silent = false) => {
    const listValidations = ['required', 'email', 'phone'];
    let validations = [];
    let validationStates = [];
    
    listValidations.forEach(validation => {
      if (element.classList.contains(validation)) validations.push(validation);
    });
    validations.forEach(validation => {
      if (validation == 'required') {
        validationStates.push(!(element.value === ''));
      } else if (element.value === '') {
        validationStates.push(true);
      } else if (validation == 'email') {
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
          
        validationStates.push(emailRegex.test(element.value));
      } else if (validation == 'phone') {
        const phoneRegex = /^[-+\d\s()]+$/;
          
        validationStates.push(phoneRegex.test(element.value));
      }
    })

    if (!silent) {
      if (validationStates.includes(false)) element.classList.add('error');
      else element.classList.remove('error');
    }

    return !validationStates.includes(false);
  };
  const submitForm = (event, formIndex) => {
    event.preventDefault();
    
    const subscribeForm = document.querySelectorAll(".external-subscribe-form-container")[formIndex].querySelector('form');
    const formData = new FormData(subscribeForm);
    const formDataJson = Object.fromEntries(formData.entries());
    
    if (validateForm(formIndex)) {
      const currentBlock = document.querySelectorAll(".external-subscribe-form-container")[formIndex];
      
      currentBlock.querySelector('form').setAttribute('style', "display: none");
      currentBlock.querySelector('.external-title').innerHTML = '${settingFormFields.grace_title}';
      currentBlock.querySelector('.external-description').innerHTML = '${settingFormFields.grace_text}';
    }
  };
  const initExternalSubscribeForm = () => {
    const subscribeBlocks = document.querySelectorAll(".external-subscribe-form-container");
    
    if (subscribeBlocks.length === 0) return;
    
    subscribeBlocks.forEach((currentBlock, index) => {
      let hasRequired = false;
      
      currentBlock.querySelectorAll("input").forEach((formInput) => {
        if (formInput.classList.contains('required')) hasRequired = true;
        
        formInput.addEventListener("input", (event) => {
          validateInput(event.target, index);
          validateForm(index);
        });
        formInput.addEventListener("focusout", (event) => {
          validateInput(event.target, index);
          validateForm(index);
        });
      });
      currentBlock.querySelector('form').addEventListener("submit", (event) => {
        submitForm(event, index);
      });
      
      if (hasRequired) setStateDisabledButton(true, index);
    });
  };

  window.addEventListener("load", initExternalSubscribeForm);
</script>`
}

export {
  formPrettySyles,
  formPrettyHtml,
  formPrettyJs
}