import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from '@/App.vue'
import components from '@/components/UI'
import LineBlock from '@/components/scenarioBuilder/LineBlock.vue'
import router from '@/router'
import axios from 'axios'
import VueClipboard from 'vue3-clipboard/dist/vue3-clipboard.min.js'
import VueApexCharts from "vue3-apexcharts"
import FloatingVue from 'floating-vue'
import Toast from "vue-toastification"
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
require('@/assets/styles/main.scss')
import "vue-toastification/dist/index.css"

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error) {
      console.log(error)
      if (error.response.status === 401) {
        if (error.response?.data?.message === 'Unauthenticated.') {
          const auth = useAuthStore()
          auth.logOut()
        }
      } else if (error.response.status === 403) {
        alert('У вас нет прав на это действие')
        router.push({ name: 'home' })
      } else {
        return Promise.reject(error)
      }
    }
  }
)

const toastOptions = {
  timeout: 4000
}

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

components.forEach(component => {
  app.component(component.name, component)
});

app.component(LineBlock.name, LineBlock)

app.use(pinia).use(router).use(VueClipboard).use(VueApexCharts).use(FloatingVue).use(Toast, toastOptions).mount('#app')

const user = useUserStore()
if (user.token !== '') {
  axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
}

window.Echo = new Echo({
  cluster: 'mt1',
  auth: {
    headers: {
      Authorization: `Bearer ${user.token}`
    }
  },
  broadcaster: 'pusher',
  authEndpoint: process.env.VUE_APP_WEBSOCKET_AUTH_ENDPOINT,
  key: 'cdpoksk',
  wsHost: process.env.VUE_APP_WEBSOCKET_WS_HOST,
  wsPort: process.env.VUE_APP_WEBSOCKET_WS_PORT,
  wsPath: '/ws',
  forceTLS: process.env.VUE_APP_WEBSOCKET_FORCE_TLS === 'true',
  disableStats: true,
})
