import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"
import { formPrettySyles, formPrettyHtml, formPrettyJs } from '@/helpers/formComponentPrettyPrint'

const toast = useToast()

export const useFormsStore = defineStore ({
  id: 'forms',
  state: () => ({
    forms: [],
    formsCount: '',
    isLoading: false
  }),
  getters: {
    preparedForms: (state) => state.forms.map(form => {
      let settingFormFields = {
        content_title: 'Присоединяйся к нам!', 
        content_description: 'Подпишись на уведомления об акциях, чтобы узнавать о скидках первым!', 
        content_button_text: 'Подписаться', 
        contacts_source_id: '', 
        contacts_source_name: '', 
        grace_title: 'Спасибо за подписку!', 
        grace_text: 'Мы отправили письмо для подтверждения вашей подписки! Перейдите в ней по ссылке.', 
        politics_url: ''
      }
      let fieldsFormConfig = {
        email: {
          enabled: 'true',
          name: 'Email',
          placeholder: 'Введите Email',
          validations: ['email'],
          isRequired: '',
          doubleOpt: 'false',
          address: '',
          senderName: '',
          param: 'email',
          value: ''
        },
        phone: {
          enabled: 'false',
          name: 'Телефон',
          placeholder: 'Введите номер телефона',
          validations: ['phone'],
          isRequired: '',
          param: 'phone',
          value: ''
        },
        name: {
          enabled: 'false',
          name: 'Имя',
          placeholder: 'Имя',
          validations: [],
          isRequired: '',
          param: 'name',
          value: ''
        }
      }
      let designFormFields = {
        window_bg_color: '#F5F6FBFF', 
        window_title_color: '#484964FF',
        window_description_color: '#484964FF', 
        fields_title_color: '#484964FF', 
        fields_description_color: '#484964FF', 
        button_bg_color: '#115EFBFF', 
        button_text_color: '#F5F6FBFF'
      }
      let code = formPrettySyles(designFormFields) + formPrettyHtml(settingFormFields, fieldsFormConfig) + formPrettyJs(settingFormFields)
      
      return {
        id: form.id.toString(),
        name: form.name,
        createdAt: form.created_at !== null ? form.created_at : '',
        updatedAt: form.updated_at !== null ? form.updated_at : '',
        sourceName: form?.source_name || '-',
        ownerName: form?.author_name || '',
        code: code,
      }
    }),
  },
  actions: {
   async getFroms() {
    this.isLoading = true
    this.forms = [
      {id: 1, name: 'форма 1', source_name: 'источник 1', created_at: '2024-09-10T09:30:46.000000Z', updated_at: '2024-09-10T09:30:46.000000Z', author_name: 'admin', code: 'представь что я html'},
      {id: 2, name: 'форма 2', source_name: 'источник 2', created_at: '2024-09-10T09:30:46.000000Z', updated_at: '2024-09-10T09:30:46.000000Z', author_name: 'admin', code: 'представь что я html'},
      {id: 3, name: 'форма 3', source_name: 'источник 3', created_at: '2024-09-10T09:30:46.000000Z', updated_at: '2024-09-10T09:30:46.000000Z', author_name: 'admin', code: 'представь что я html'},
      {id: 4, name: 'форма 4', source_name: 'источник 4', created_at: '2024-09-10T09:30:46.000000Z', updated_at: '2024-09-10T09:30:46.000000Z', author_name: 'admin', code: 'представь что я html'},
    ]
    this.formsCount = 4
    this.isLoading = false
   },
   async deleteForm() {
    if (confirm("Вы действительно хотите удалить?")) {
      this.isLoading = true
      toast.success('Форма успешно удалена')
      this.isLoading = false
    }
   }
  },
})